import { publicAPI } from "@lesmills/gatsby-theme-common"
import { useEffect, useState } from "react"
import { getRolloverCountries } from "../../../graphql/getRolloverCountries"
import { AXII_CONFIGS } from "../../../../../../src/configs/axii-config"

const lowerCaseCountryCodes = rollOverCountries =>
  Object.keys(rollOverCountries).reduce(
    (acc, key) => {
      const values = rollOverCountries[key]
      if (Array.isArray(values)) {
        acc[key] = values.map(value => value.toLowerCase())
      }
      return acc
    },
    { threeMonth: [], twelveMonth: [] }
  )

const useGetRolloverCountries = () => {
  const defaultRolloverCountries =
    process.env.GATSBY_RT_25_09_2023_ROLLOVER_COUNTRIES !== "true"
      ? {
          threeMonth: AXII_CONFIGS.multiMonthRolloverCountries,
          twelveMonth: AXII_CONFIGS.twelveMonthRolloverCountry,
        }
      : null
  const [rolloverCountries, setRolloverCountries] = useState(
    defaultRolloverCountries
  )

  useEffect(() => {
    if (process.env.GATSBY_RT_25_09_2023_ROLLOVER_COUNTRIES !== "true") {
      return
    }
    publicAPI(
      getRolloverCountries,

      // Call back after get headers api
      ({ data }) => {
        const rolloverCountries = lowerCaseCountryCodes(
          data?.data?.getRolloverCountries
        )
        setRolloverCountries(rolloverCountries)
      }
    )
  }, [])

  return { rolloverCountries }
}

export default useGetRolloverCountries
