import React, { memo, useState } from "react"
import isEqual from "lodash/isEqual"
import type {
  SubscriptionType,
  AllLayoutsType,
} from "@lesmills/gatsby-theme-common"
import type { MyAccountPageType } from "../../types/MyAccountPageType"
import {
  Notification,
  Button,
  formatDateTime,
  ROUTES,
  convertCentsToEuro,
  DATETIME_FORMAT,
  CURRENCIES,
  allowRolloverPreferences,
  getSubscriptionStatus,
  hideRolloverPreferences,
  SUBSCRIPTION_STATES,
  isNotCompletedSubscription,
  Spinner,
  isAffiliateUser,
  getIncTaxText,
  signupChannelsMessages,
} from "@lesmills/gatsby-theme-common"

import InformationWrapper from "../InformationWrapper"
import InformationRow from "../InformationRow"
import { isFailedSubscription } from "../../utils/payment"
import RolloverPreferences from "../CreateAccount/RolloverPreferences"
import useGetRolloverCountries from "./hooks/useGetRolloverCountries"

type Props = {|
  subscriptionDetailSection: Object,
  currentSubscription: Object,
  sectionStyles?: Object,
  isSubscriptionCanceled: () => void | Promise<void>,
  handleSubscription: () => void | Promise<void>,
  handleDeleteSubscription: () => void | Promise<void>,
  handleOpenContinueCurrentSubscription: () => void | Promise<void>,
  renderReminder: () => void | Promise<void>,
  nextSubscription: SubscriptionType,
  dataPrismic: MyAccountPageType,
  lang: string,
  layoutData?: AllLayoutsType,
  isProcessing?: Boolean,
  handleUpdateSubscriptionPreferences: () => void | Promise<void>,
  userCountry: String,
  isNotIncludedTax?: Boolean,
  plusTaxText: String,
  isResellerUser: Boolean,
  isResellerCustomerUser: Boolean,
  isIAP: Boolean,
  signupChannel: String,
|}

const SubscriptionDetailSection = ({
  subscriptionDetailSection,
  handleSubscription,
  handleDeleteSubscription,
  handleOpenContinueCurrentSubscription,
  lang,
  isProcessing,
  renderReminder = () => {},
  isSubscriptionCanceled = () => {},
  currentSubscription = {},
  sectionStyles = {},
  nextSubscription = {},
  dataPrismic = {},
  layoutData = {},
  handleUpdateSubscriptionPreferences = () => {},
  userCountry = "",
  plusTaxText = "",
  isNotIncludedTax = false,
  isResellerUser = false,
  isResellerCustomerUser = false,
  isIAP,
  signupChannel = "",
}: Props) => {
  const [allowRollover, setAllowRollover] = useState(
    currentSubscription.default_to_monthly
  )

  const { rolloverCountries } = useGetRolloverCountries()

  const { items = [], primary = {}, restart_subscription_reminder = [] } =
    subscriptionDetailSection || {}
  const { update_url = {}, section_title = {} } = primary
  const [
    subscriptionTypeLabel = {},
    nextBillingDateLabel = {},
    subscriptionStatusLabel = {},
  ] = items

  const {
    changed_subscription_reminder = { text: "" },
    changed_subscription_reminder_rc = { text: "" },
    continue_current_subscription_label = {},
    pending_payment_label = {},
    overdue_payment_label = {},
    restart_subscription_label = {},
    retry_subscription_button_label = {},
    cancel_subscription_button_label = {},
  } = dataPrismic
  const currentSignupChannel = signupChannelsMessages[signupChannel]
  const currentSignupChannelMessages =
    isIAP && currentSignupChannel
      ? {
          message: dataPrismic[currentSignupChannel.message].text || "",
          subscription:
            dataPrismic[currentSignupChannel.subscription].text || "",
        }
      : {}
  const subscriptionCanceled = isSubscriptionCanceled(currentSubscription)
  const handleSubscriptionLabel = subscriptionCanceled
    ? restart_subscription_label
    : cancel_subscription_button_label

  const {
    current_period_ends_at,
    state,
    promotion_label,
    subscription_type,
    preview_renewal,
  } = currentSubscription || {}
  const { name, currency } = currentSubscription.product || {}

  const price = preview_renewal?.totalInCentsTaxExclusive

  const nextCurrency = CURRENCIES[nextSubscription.currency || currency]

  const renderChangedSubscriptionReminder = () => {
    const changedSubscriptionReminderText = isResellerCustomerUser
      ? changed_subscription_reminder_rc
      : changed_subscription_reminder
    const changedSubscriptionReminder = changedSubscriptionReminderText.text.replace(
      "@next_subscription",
      nextSubscription.name
    )

    return renderReminder(
      changedSubscriptionReminder,
      handleOpenContinueCurrentSubscription,
      continue_current_subscription_label.text,
      isProcessing
    )
  }

  const isProcessingSubscription = state === SUBSCRIPTION_STATES.PROCESSING
  const status = getSubscriptionStatus(currentSubscription)
  /**
   * JIRA ticket: https://lesmillsinternational.atlassian.net/browse/LA-796
   *  - If the new created subscription is On Hold due to 'failed' payment,
   the Next Billing Date on My Account page should display the text "Pending Payment".
   *  - If the renewed subscription is On Hold due to 'failed recurring' payment,
   the Next Billing Date on My Account page should display the text "Overdue".
   * @param {String} status: Subscription status
   */
  const renderNextBillingText = subscriptionStatus => {
    switch (subscriptionStatus) {
      // "Pending Payment"
      case SUBSCRIPTION_STATES.FAILED:
        return pending_payment_label.text

      // "Overdue"
      case SUBSCRIPTION_STATES.ON_HOLD:
      case SUBSCRIPTION_STATES.PAST_DUE:
      case SUBSCRIPTION_STATES.EXPIRED:
      case SUBSCRIPTION_STATES.UNPAID:
        return overdue_payment_label.text

      default:
        // Format: 29 Jun 2020 - €1.19 (inkl. MwSt)
        return `${formatDateTime(
          current_period_ends_at,
          DATETIME_FORMAT.default,
          layoutData
        )}
          ${
            !isNaN(price) && price !== null
              ? ` - ${nextCurrency}${convertCentsToEuro(
                  price,
                  nextSubscription.currency || currency
                )} (${
                  // https://lesmillsinternational.atlassian.net/browse/LA-1221
                  // The price of subscription in US and CA is not included tax
                  // Add "plus Tax" right after price in US and CA
                  isNotIncludedTax
                    ? plusTaxText
                    : getIncTaxText(userCountry, layoutData)
                })`
              : ""
          }`
    }
  }

  const hideChangeSubscription =
    subscriptionCanceled ||
    isAffiliateUser(currentSubscription) || // LA-1339: Use both offer_tune_id and offer_id to indicate affiliate user
    isFailedSubscription(currentSubscription) ||
    isProcessingSubscription || // hide change subscription while processing subscription
    isResellerUser || // hide change subscription with Reseller User
    isResellerCustomerUser || // hide change subscription with Reseller Customer User
    isIAP // AB2B-695: hide change subscription with ApplePay user

  const handleChangeRolloverOption = async () => {
    await handleUpdateSubscriptionPreferences(() => {
      setAllowRollover(!allowRollover)
    }, !allowRollover)
  }
  // const signupChannelsMessages = {
  //   appleiap: {
  //     subscription: applepay_subscription.text,
  //     message: applepay_message.text,
  //   },
  //   vimeorokuiap: {
  //     subscription: roku_subscription.text,
  //     message: roku_message.text,
  //   },
  //   accedofireiaap: {
  //     subscription: accedo_fire_subscription.text,
  //     message: accedo_fire_message.text,
  //   },
  //   accedogoogleiap: {
  //     subscription: accedo_google_subscription.text,
  //     message: accedo_google_message.text,
  //   },
  // }

  return (
    <InformationWrapper
      title={section_title.text}
      updateLink={
        hideChangeSubscription
          ? {}
          : {
              label: update_url.text,
              url: ROUTES(lang).CHANGE_SUBSCRIPTION,
            }
      }
      externalLink={
        process.env.GATSBY_RT_05_04_2022_CHANGE_SUBSCRIPTION === "true"
          ? process.env.GATSBY_CHANGE_SUBSCRIPTION_LINK_NEW ||
            `${process.env.GATSBY_GETTING_STARTED_URL}subscription/`
          : null
      }
      classNames={sectionStyles}
    >
      {/* Subscription type */}
      <InformationRow
        label={subscriptionTypeLabel.name && subscriptionTypeLabel.name.text}
        value={
          isIAP
            ? currentSignupChannelMessages.subscription
            : name || subscription_type
        }
        testId="subscription-type"
      />
      {isIAP && (
        <p className="font-base-light md:text-base md:leading-7none text-gray-500 leading-2normal text-3xs">
          {currentSignupChannelMessages.message}
        </p>
      )}
      {Object.keys(nextSubscription).length > 0 && !subscriptionCanceled && (
        <Notification
          type="info"
          message={
            <span className="font-base-light md:text-2lg md:leading-7none text-gray-500 leading-2normal text-3xs">
              {renderChangedSubscriptionReminder()}
            </span>
          }
          classNames={{ wrapper: "mt-15 w-full" }}
        />
      )}

      {/* Next Billing date */}
      {subscriptionCanceled
        ? !(
            isResellerCustomerUser && status === SUBSCRIPTION_STATES.SUSPENDED
          ) && (
            <Notification
              type="error"
              message={restart_subscription_reminder.text}
              classNames={{ wrapper: "mt-5 w-full" }}
              testId="notification-subscription-canceled"
            />
          )
        : // AB2B-695:Hide next billing date for applepay
          !isIAP && (
            <InformationRow
              label={
                nextBillingDateLabel.name && nextBillingDateLabel.name.text
              }
              value={renderNextBillingText(state)}
              testId="next-billing-date"
            />
          )}
      {/* AB2B-521: Show promotion name for telstra */}
      {promotion_label ? (
        <div className="leading-2normal text-3xs font-base-light text-white px-8 rounded-7 bg-blue-500 w-max-content">
          {promotion_label}
        </div>
      ) : null}

      {/* Rollover Preferences */}
      {// LA-2240: Hide rollover for R and RC user
      allowRolloverPreferences({
        subscription: {
          ...currentSubscription.product,
          tune_offer_id: currentSubscription.tune_offer_id,
        },
        userCountry,
        rolloverCountries,
      }) &&
        !hideRolloverPreferences(nextSubscription, currentSubscription) &&
        isNotCompletedSubscription(currentSubscription) && (
          <div className="relative">
            {isProcessing && <Spinner />}
            <RolloverPreferences
              disabled={isProcessing}
              layoutData={layoutData}
              currentSubscription={currentSubscription.product}
              onChange={handleChangeRolloverOption}
              classNames={{
                wrapper: "mt-20 md:mt-25",
                buttonWrapper: " md:w-260 w-full mt-10 mb-10",
                button: " md:text-base md:leading-22 text-3xs leading-20",
                message: " md:text-base md:leading-22 text-3xs leading-20",
                question: " md:text-2lg md:leading-24 text-base leading-22",
              }}
              rolloverOption={`${allowRollover}`}
            />
          </div>
        )}

      <div
        className={`flex flex-wrap justify-center items-center ${
          isIAP ? "md:justify-end" : "md:justify-between"
        }`}
      >
        {!isIAP && (
          <InformationRow
            label={
              subscriptionStatusLabel.name && subscriptionStatusLabel.name.text
            }
            value={dataPrismic[status]?.text}
            classNames={{
              wrapper: " md:w-1/2 w-full",
              value: " capitalize",
            }}
            testId="subscription-status"
          />
        )}
        {/* Should call handleDeleteSubscription for failed subscription */}
        {/* Hide button while processing subscription */}
        {/* Hide cancel subscription button for reseller user and reseller customer */}
        {!isResellerUser &&
          !isResellerCustomerUser &&
          !isProcessingSubscription &&
          !isIAP &&
          (state === SUBSCRIPTION_STATES.FAILED ? (
            <Button
              handleOnClick={() => handleDeleteSubscription()}
              className="btn btn-layout md:w-btn-md w-textbox-base mt-20 md:mt-25"
              disable={isProcessing}
            >
              {retry_subscription_button_label.text}
            </Button>
          ) : (
            <Button
              handleOnClick={() => handleSubscription()}
              className="btn btn-layout md:w-btn-md w-textbox-base mt-20 md:mt-25"
            >
              {handleSubscriptionLabel.text}
            </Button>
          ))}
      </div>
    </InformationWrapper>
  )
}

export default memo(SubscriptionDetailSection, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.currentSubscription, nextProps.currentSubscription) &&
    isEqual(prevProps.nextSubscription, nextProps.nextSubscription) &&
    prevProps.isProcessing === nextProps.isProcessing
  )
})
